@import "variable";
@import "theme/font";
.input__group {

  label.input__label {
    color: $bg-gray-label;
    font-size: $font-size-base;
    font-family: 'avertaB';

    ::ng-deep .label__required {
      color: $bg-error-default;
    }
  }

  .input__message {
    font-size: 12px;
    margin-top: 8px;
    color: $bg-gray-label;

    i {
      margin-right: 8px;
    }

    .text__message {
      font-family: avertaL;
    }
  }

  .input__message--warning {

    i, span {
      color: $bg-warning-default;
    }
  }

  .input__message--error {

    i, span {
      color: $bg-error-default;
    }
  }

  .input__message--success {

    i, span {
      color: $bg-success-default;
    }
  }

  .input__text {
    font-size: $font-size-base;
    color: $bg-gray-body;
    outline: none;
    box-shadow: none;
    resize: none;

    @include placeholder {
      color: $bg-gray-placeholder;
      font-size: $font-size-base;
    }

    &:active, &:focus {
      &:enabled {
        @include placeholder {
          color: $bg-gray-line;
        }
      }
    }
  }
}

//Input no icon
.input__group.input__group--no-icon {

  .input__text {
    border: 1px solid;
    padding: 10px 0 10px 8px;
    border-radius: 8px;
    margin-top: 4px;
  }

  //Default
  .input__text--default {
    border-color: $bg-gray-line;

    &:hover, &:focus {
      border-color: $bg-primary-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-gray-line;
      }
    }
  }

  //Warning
  .input__text--warning {
    border-color: $bg-warning-light;

    &:hover, &:focus {
      border-color: $bg-warning-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-warning-light;
      }
    }
  }

  //Error
  .input__text--error {
    border-color: $bg-error-light;

    &:hover, &:focus {
      border-color: $bg-error-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-error-light;
      }
    }
  }

  //Success
  .input__text--success {
    border-color: $bg-success-light;

    &:hover, &:focus {
      border-color: $bg-success-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-success-light;
      }
    }
  }
}

//Input icon
.input__group.input__group--icon {

  .input__group--group {
    border: 1px solid;
    padding: 10px 0 10px 8px;
    border-radius: 8px;
    margin-top: 4px;
    outline: none;
    box-shadow: none;
    align-items: center;

    ::ng-deep i {
      color: $bg-gray-body;
      font-size: $font-size-base;
      margin-right: 5px;
    }
  }

  //Default
  .input__group--group--default {
    border-color: $bg-gray-line;

    &:hover, &:focus {
      border-color: $bg-primary-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-gray-line;
      }
    }
  }

  //Warning
  .input__group--group--warning {
    border-color: $bg-warning-light;

    &:hover, &:focus {
      border-color: $bg-warning-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-warning-light;
      }
    }
  }

  //Error
  .input__group--group--error {
    border-color: $bg-error-light;

    &:hover, &:focus {
      border-color: $bg-error-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-error-light;
      }
    }
  }

  //Success
  .input__group--group--success {
    border-color: $bg-success-light;

    &:hover, &:focus {
      border-color: $bg-success-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-success-light;
      }
    }
  }
}

::ng-deep .ant-upload.ant-upload-drag {
  border: 1px dashed $bg-gray-line !important;
  background-color: white;

  &:hover, &:focus {
    border-color: $bg-primary-default !important;
  }
}

.select__group.select__group--compact {

  ::ng-deep .ant-select-selector {
    border: none;
    height: 22px !important;

    ::ng-deep .ant-select-selection-search-input, ::ng-deep .ant-select-selection-item {
      height: 22px;
      line-height: 22px;
    }
  }

  @include placeholder {
    color: $bg-gray-placeholder;
    font-size: $font-size-base;
  }
}

.input__group--compacts--flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.group__action--form-array {
  display: flex;
}

::ng-deep .select__group--compact.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  box-shadow: none;
  outline: 0;
}
