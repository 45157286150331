@font-face {
  font-family: avertaR;
  src: url(~/assets/fonts/averta/AvertaStdCY-Regular.otf);
}
@font-face {
  font-family: avertaB;
  src: url(~/assets/fonts/averta/AvertaStdCY-Bold.otf);
}
@font-face {
  font-family: avertaSB;
  src: url(~/assets/fonts/averta/AvertaStdCY-Semibold.otf);
}
@font-face {
  font-family: avertaBlack;
  src: url(~/assets/fonts/averta/AvertaStdCY-Black.otf);
}
@font-face {
  font-family: avertaL;
  src: url(~/assets/fonts/averta/AvertaStdCY-Light.otf);
}
@font-face {
  font-family: avertaRI;
  src: url(~/assets/fonts/averta/AvertaStdCY-RegularItalic.otf);
}
@font-face {
  font-family: avertaBI;
  src: url(~/assets/fonts/averta/AvertaStdCY-BoldItalic.otf);
}
@font-face {
  font-family: avertaSBI;
  src: url(~/assets/fonts/averta/AvertaStdCY-SemiboldItalic.otf);
}
@font-face {
  font-family: avertaBlackI;
  src: url(~/assets/fonts/averta/AvertaStdCY-BlackItalic.otf);
}
@font-face {
  font-family: avertaLI;
  src: url(~/assets/fonts/averta/AvertaStdCY-LightItalic.otf);
}
@font-face {
  font-family: avertaEB;
  src: url(~/assets/fonts/averta/AvertaStdCY-Extrabold.otf);
}
@font-face {
  font-family: avertaEBI;
  src: url(~/assets/fonts/averta/AvertaStdCY-ExtraboldItalic.otf);
}
@font-face {
  font-family: avertaET;
  src: url(~/assets/fonts/averta/AvertaStdCy-Extrathin.otf);
}
@font-face {
  font-family: avertaETI;
  src: url(~/assets/fonts/averta/AvertaStdCy-ExtrathinItalic.otf);
}
@font-face {
  font-family: avertaT;
  src: url(~/assets/fonts/averta/AvertaStdCY-Thin.otf);
}
@font-face {
  font-family: avertaTI;
  src: url(~/assets/fonts/averta/AvertaStdCY-ThinItalic.otf);
}
