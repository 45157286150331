$bg-layout: #f3f3f3;
//Success
$bg-success-dark: #00966D;
$bg-success-default: #00BA88;
$bg-success-light: #34EAB9;
$bg-success-bg: #C7F8EA;
//Warning
$bg-warning-dark: #946200;
$bg-warning-default: #F4B740;
$bg-warning-light: #FFDE9F;
$bg-warning-bg: #FFF2DA;
//Alert
$bg-alert-dark: #FA0B0B;
$bg-alert-default: #FA4949;
$bg-alert-light: #FF8787;
$bg-alert-bg: #FFC9C9;
//Error
$bg-error-dark: #C30052;
$bg-error-default: #ED2E7E;
$bg-error-light: #FF84B7;
$bg-error-bg: #FCDCEA;
//Primary
$bg-primary-dark: #24264E;
$bg-primary-darker: #00009A;
$bg-primary-default: #141ED2;
$bg-primary-light: #7F85F3;
$bg-primary-bg: #E9EAFF;
//Dayblue
$bg-dayblue-dark: #76CCE7;
$bg-dayblue-default: #66CAFF;
$bg-dayblue-light: #99DCFF;
$bg-dayblue-bg: #D6F1FF;
//Secondary
$bg-secondary-dark: #7900FF;
$bg-secondary-default: #9B40FF;
$bg-secondary-light: #BC80FF;
$bg-secondary-bg: #EEDFFF;
//Gray
$bg-gray-dark: #14142A;
$bg-gray-body: #4E4B66;
$bg-gray-label: #6E7191;
$bg-gray-placeholder: #A0A3BD;
$bg-gray-placeholder-dark: #9B9B9B;
$bg-gray-dark-light: #C2C9D1;
$bg-gray-line: #D9DBE9;
$bg-gray-input: #F5F5F5;
$bg-gray-input-light: #F7F7F7;
$bg-gray-bg: #F7F7FC;
$bg-gray-off-white: #FCFCFC;
//Sidebar
$bg-sidebar-primary: #000056;
$bg-sidebar-menu-active: rgba(255, 255, 255, 0.14);
$bg-sidebar-text-active: #fff;
//Scrollbar
$bg-scrollbar-icon: #14142B;
$bg-scrollbar-bg: #E8E8EA;
//Button color
$bt-primary-default: #141ED2;
$bt-primary-dark: #00009A;
$bt-primary-darker: #24264E;
$bt-primary-gray: #F5F5F5;

//Border-radius
$border-radius-default: 16px;
$border-radius-small: 10px;

//Breadcrumb
$color-breadcrumb-title: #A0A3BD;
$color-breadcrumb-title-active: #14142B;

//Content
$bg-content-default: #fff;

//Modal
$bg-modal-header: $bt-primary-default;
$bg-modal-header-title: #FFFFFF;

// Checkbox
$bg-checkbox-inner: $bt-primary-default;



//Placeholder
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
