/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';
@import url('../node_modules/ng-zorro-antd/src/ng-zorro-antd.min.css');

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~material-icons-font/sass/variables';
@import '~material-icons-font/sass/mixins';
$MaterialIcons_FontPath: '~material-icons-font/fonts';
@import '~material-icons-font/sass/main';
@import '~material-icons-font/sass/Regular';
@import '../node_modules/ng-zorro-antd/resizable/style/index.min.css';

@import 'app/core/global-style/utilities';
@import 'app/core/global-style/common-styles';

@import 'styles/theme/color';
@import 'app/core/global-style/color';
@import 'styles/theme/font';
@import 'app/core/global-style/font';
@import 'styles/theme/mixins';
@import 'styles/theme/variables';

//@import 'styles/theme/reset';
@import 'styles/theme/themes/config/base';

@import 'app/core/global-style/scrollbar';
@import 'app/core/global-style/scroll-tabs';
@import 'app/core/global-style/button';
@import 'app/core/global-style/modal';
@import 'app/core/global-style/date-picker';
@import 'app/core/global-style/collapse';
@import 'app/core/global-style/ngx-toastr';

@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import "app/core/global-style/table";
@import "app/core/global-style/pagination";
@import 'app/core/global-style/checkbox';
@import 'app/core/global-style/card-item';
@import 'app/core/global-style/input';


//@font-face {
//  font-family: lato;
//  src: url(assets/fonts/Lato-Regular_2.ttf) format('opentype');
//}
//
//* {
//  font-family: 'lato';
//}
//
//body {
//  background: #f4f6fa;
//  overflow: hidden;
//}

html, body {
  font-family: avertaR;
}

.content__item {
  padding: 16px;
}

.content__item--border {
  border-radius: 8px;
}

.mb__figure {
  margin: 0;
}

.full_width {
  width: 100%;
}

.data-table {

  overflow-x: auto;

  th, td {
    padding: 5px;
  }

  .text-center {
    text-align: center;
  }

  .text-nowrap {
    white-space: nowrap;
  }

  .text-right {
    text-align: right;
  }
}

.bs-datepicker-body table td.week span {
  color: #000056 !important;
}

.bs-datepicker-head {
  background-color: #000056 !important;
}

.bs-datepicker-body table td span.selected {
  background-color: #000056 !important;
}

mark {
  color: red;
  background-color:unset ;
}

.top-sticky {
  position: sticky;
  top: 0px;
  z-index: 999;
  background-color: #ffffff;
}
