@import "variable";

.ant-modal-content {
  border-radius: $border-radius-default;
  .ant-modal-header {
    border-radius: $border-radius-default $border-radius-default 0 0;
    // background-color: $bg-modal-header;

    .ant-modal-title{
      // color: $bg-modal-header-title;
      font-size: 1.1rem ;
      font-family: 'avertaB' ;
    };

      // &:after {
      //   position: absolute;
      //   content: url(~assets/icon/header/modal-header-shape-1.svg);
      //   margin-left: 40%
      // };

      // &:before {
      //   position: absolute;
      //   content: url(~assets/icon/header/modal-header-shape-2.svg);
      //   margin-left: 60%

      // }
  }

  .ant-upload.ant-upload-drag {
    height: unset!important;
  }

  // .ant-modal-close span {
  //   color: $bg-modal-header-title;
  //   font-size: 1.1rem ;
  // }
}
