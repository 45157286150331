@import "variable";

:host(.scroll__tab--sticky) {
  .scroll__tab{
    position: sticky;
    top: 0px;
    z-index: 999;
  }
}
.scroll__tab {
  .scroll__tab--title {
    color: $bg-gray-dark;
    font-family: 'avertaB';
    font-weight: 600;
    line-height: 150%;
    font-size: 1rem;
    padding: 1rem;
    &:hover, &:active {
        border-bottom: 3px solid $bt-primary-dark;

      &::after {
        border: none!important;
      }
    }


  }

  .ant-menu-item-selected {
    border-bottom: 3px solid $bt-primary-dark;

    &::after {
      border: none!important;
    }
  }


}
@media screen and (max-width: 767px){
  .scroll__tab {
    display: flex;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .scroll__tab--title {
      padding: 10px;
    }
  }
}
