@import "variable";
@import "theme/font";
.ant-checkbox-wrapper {
  color: $bg-gray-label;
  font-size: $font-size-base;
  font-family: avertaL;

  .ant-checkbox {
    &::hover, &::focus {
      input {
        border-color: $bg-checkbox-inner;
      }
    }
  }
}

.ant-checkbox-wrapper-checked {
  font-family: 'avertaB';
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $bg-checkbox-inner;
      border-color: $bg-checkbox-inner;
    }

    &::after, &::hover {
      border-color: $bg-checkbox-inner;
    }
  }

  .ant-checkbox {
    &::hover input {
      border-color: $bg-checkbox-inner;
    }
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $bg-checkbox-inner;
}

.checkbox__label--bold {
  .ant-checkbox-wrapper {
    font-family: 'avertaB';
  }
}
