@import "variable";
@import "theme/font";
.ant-btn {
  font-size: $font-size-base;
  margin-left: 20px;
  border-radius: 8px;
  padding: 4px 16px;
  border-color: $bg-primary-default;
  color: $bg-primary-default;
}
.ant-btn-sm {
  padding: 0px 10px 0px 10px;
}

.ant-btn-primary {
  background: $bg-primary-default;
  border-color: $bg-primary-default;
  color: #fff;

  &:hover {
    background: $bg-primary-darker;
    border-color: $bg-primary-darker;
  }
}

.button__class--no-padding .ant-btn.button__class {
  padding: 0!important;
}

.button__class--no-margin .ant-btn.button__class {
  margin: 0!important;
}

.ant-btn.button__class {
  line-height: 1.2;
}
.button__class--only-icon {
  cursor: pointer;
  margin-left: 15px;
  color: $bg-primary-default;
}
.ant-btn.button__class--small {
  padding: 4px 8px;
  font-size: 12px;
  height: 24px;
  border-radius: 4px;
}
.ant-btn.button__class--medium {
  padding: 8px 16px;
  font-size: 12px;
  border-radius: 4px;
}
.ant-btn.button__class--large {
  padding: 10px 18px;
  font-size: $font-size-base;
  border-radius: 6px;
  height: 40px;
}
.ant-btn.button__class--very_large {
  padding: 10px 16px;
  font-size: $font-size-base;
  border-radius: 6px;
  height: 44px;
}
.ant-btn.button__class--no-border {
  border: none;
}
.button__class--dash-vertical {
  position: absolute;
  width: 1px;
  border-left: 1px solid;
  top: -1px;
  right: 25px;
}
.ant-btn.button__class--show-dash.button__class--small {
  padding-right: 33px;

  .suffix__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
  }
}
.ant-btn.button__class--show-dash.button__class--medium {
  padding-right: 41px;

  .suffix__icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
  }
}

.ant-btn.button__class--show-dash.button__class--large {
  padding-right: 41px;

  .suffix__icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
  }
}
.ant-btn.button__class--show-dash.button__class--very_large {
  padding-right: 41px;

  .suffix__icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
  }
}
.ant-btn.button__class.button__class--content {
  height: auto;

  > span {
    display: none;
  }

  .mb__button--content {
    width: 100%;
  }
}
