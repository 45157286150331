@import 'theme/font';
@import "variable";

.datepicker__group {
  ::ng-deep.ant-picker-range .ant-picker-clear {
    right: 0;
  }
  label.input__label {
    color: $bg-gray-label;
    font-size: $font-size-base;
    font-family: 'avertaB';

    ::ng-deep .label__required {
      color: $bg-error-default;
    }
  }

  .ant-picker {
    width: 100%;

    .ant-picker-clear {
      right: 0;
    }
  }

  .datepicker__message {
    font-size: 12px;
    margin-top: 8px;
    color: $bg-gray-label;

    i {
      margin-right: 8px;
    }

    .text__message {
      font-family: avertaL;
    }
  }

  .datepicker__message--warning {

    i, span {
      color: $bg-warning-default;
    }
  }

  .datepicker__message--error {

    i, span {
      color: $bg-error-default;
    }
  }

  .datepicker__message--success {

    i, span {
      color: $bg-success-default;
    }
  }

  .ant-picker-input input{
    font-size: $font-size-base;
    color: $bg-gray-body;
    outline: none;
    box-shadow: none;

    @include placeholder {
      color: $bg-gray-placeholder;
      font-size: $font-size-base;
    }

    &:active, &:focus {
      &:enabled {
        @include placeholder {
          color: $bg-gray-line;
        }
      }
    }
  }
}

//datepicker no icon
.datepicker__group.datepicker__group--no-icon {

  .datepicker__text {
    border: 1px solid;
    padding: 10px 0 10px 8px;
    border-radius: 8px;
    margin-top: 4px;
  }

  //Default
  .datepicker__text--default {
    border-color: $bg-gray-line;

    &:hover, &:focus {
      border-color: $bg-primary-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-gray-line;
      }
    }
  }

  //Warning
  .datepicker__text--warning {
    border-color: $bg-warning-light;

    &:hover, &:focus {
      border-color: $bg-warning-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-warning-light;
      }
    }
  }

  //Error
  .datepicker__text--error {
    border-color: $bg-error-light;

    &:hover, &:focus {
      border-color: $bg-error-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-error-light;
      }
    }
  }

  //Success
  .datepicker__text--success {
    border-color: $bg-success-light;

    &:hover, &:focus {
      border-color: $bg-success-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-success-light;
      }
    }
  }
}

//datepicker icon
.datepicker__group.datepicker__group--icon{

  .datepicker__group--group  .ant-picker{
    //border: 1px solid;
    padding: 10px 0 10px 8px;
    border-radius: 8px;
    margin-top: 4px;
    outline: none;
    box-shadow: none;
    align-items: center;

    ::ng-deep i {
      color: $bg-gray-label;
      font-size: 1.1rem;
      margin-right: 1rem;
    }
  }

  //Default
  .datepicker__group--group--default  .ant-picker{
    border-color: $bg-gray-line;

    &:hover, &:focus {
      border-color: $bg-primary-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-gray-line;
      }
    }
  }

  //Warning
  .datepicker__group--group--warning  .ant-picker{
    border-color: $bg-warning-light;

    &:hover, &:focus {
      border-color: $bg-warning-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-warning-light;
      }
    }
  }

  //Error
  .datepicker__group--group--error  .ant-picker{
    border-color: $bg-error-light;

    &:hover, &:focus {
      border-color: $bg-error-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-error-light;
      }
    }
  }

  //Success
  .datepicker__group--group--success  .ant-picker{
    border-color: $bg-success-light;

    &:hover, &:focus {
      border-color: $bg-success-default;
    }

    &:disabled {
      background: $bg-gray-input;

      &:hover, &:focus {
        border-color: $bg-success-light;
      }
    }
  }
}
