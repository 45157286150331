// Custom Color
$custom-1: #fff !default;

// Start with assigning color names to specific hex values.
$white:                     #fff !default;
$black:                     #000 !default;
$red:                       #de4436 !default;
$orange:                    #fa8c16 !default;
$gold:                      #ffc107 !default;
$green:                     #52c41a !default;
$blue:                      #3f87f5 !default;
$cyan:                      #00c9a7 !default;
$purple:                    #886cff  !default;
$magenta:                   #eb2f96 !default;
$volcano:                   #fa541c !default;
$lime:                      #a0d911 !default;
$geekblue:                  #2f54eb !default;
$blue-brand:                #133066 !default;
$white-brand:               #D6E4FF !default;


// Create grayscale
$gray-dark:                 #2a2a2a !default;
$gray:                      #53535f !default;
$gray-light:                #34404B  !default;
$gray-lighter:              #ededed !default;
$gray-lightest:             #f1f2f3 !default;

// Two Tones
$tone-magenta:              $magenta !default;
$tone-magenta-light:        rgba($magenta, 0.05) !default;

$tone-red:                  $red !default;
$tone-red-light:            rgba($red, 0.05) !default;

$tone-volcano:              $volcano !default;
$tone-volcano-light:        rgba($volcano, 0.05) !default;

$tone-orange:               $orange !default;
$tone-orange-light:         rgba($orange, 0.1) !default;

$tone-gold:                 $gold !default;
$tone-gold-light:           rgba($gold, 0.1) !default;

$tone-lime:                 $lime !default;
$tone-lime-light:           rgba($lime, 0.1) !default;

$tone-green:                $green !default;
$tone-green-light:          rgba($green, 0.1) !default;

$tone-cyan:                 $cyan !default;
$tone-cyan-light:           rgba($cyan, 0.1) !default;

$tone-blue:                 $blue !default;
$tone-blue-light:           rgba($blue, 0.1) !default;

$tone-geekblue:             $geekblue !default;
$tone-geekblue-light:       rgba($geekblue, 0.1) !default;

$tone-purple:               $purple !default;
$tone-purple-light:         rgba($purple, 0.1) !default;


$tone-loop: (
  "magenta"     $tone-magenta     $tone-magenta-light,
  "red"         $tone-red         $tone-red-light,
  "volcano"     $tone-volcano     $tone-volcano-light,
  "orange"      $tone-orange      $tone-orange-light,
  "gold"        $tone-gold        $tone-gold-light,
  "lime"        $tone-lime        $tone-lime-light,
  "green"       $tone-green       $tone-green-light,
  "cyan"        $tone-cyan        $tone-cyan-light,
  "blue"        $tone-blue        $tone-blue-light,
  "geekblue"    $tone-geekblue    $tone-geekblue-light,
  "purple"      $tone-purple      $tone-purple-light
);

// State Colors
$brand-primary:             $blue !default;
$brand-secondary:           $purple !default;
$brand-success:             $cyan !default;
$brand-info:                $blue !default;
$brand-warning:             $gold !default;
$brand-danger:              $red !default;
$brand-default:             $gray-dark !default;

// My custom
$white-color: #fff;
$search-color: rgba(247, 247, 247, 1);
$search-border-color: #f3f3f3;
$gray-background: #9b9b9b;
$background-app-color: #000056;
$primary-button-color: #141ed2;
$background-main: #f4f6fa;
