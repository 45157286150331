.action-group {
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  background: inherit;
}

label {
  font-weight: 700;
}
