@import "variable";
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

*::-webkit-scrollbar-track {
  border: $bg-scrollbar-bg;
  background-color: $bg-scrollbar-bg;
}

*::-webkit-scrollbar-thumb {
  background-color: $bg-scrollbar-icon;
  border: $bg-scrollbar-icon;
  border-radius: 5px;
}
