$bg-pagination-active: #E8EAF6;
$color-text-header: #4E4B66;
$bg-pagination-border: #F4F6FA;
$color-pagination-hover: #000056;

.ant-pagination-item:not(.ant-pagination-item-active) {
  border-radius: 50%;

  &:focus-visible, &:hover {
    a {
      color: $color-pagination-hover;
      font-weight: bold;
    }
  }
}

.ant-pagination-prev, .ant-pagination-next {
  border-radius: 50%;

  .ant-pagination-item-link {
    border-radius: 50%;
  }

  &:focus-visible, &:hover {
    i {
      color: $color-pagination-hover;
      font-weight: bold;
    }
  }
}

.ant-pagination-item-active {
  font-weight: 500;
  background: $bg-pagination-active;
  border-radius: 50%;
  border-color: $bg-pagination-border;

  a {
    color: $color-text-header;
  }

  &:focus-visible, &:hover {
    a {
      color: $color-text-header;
      font-weight: bold;
    }
    border-color: $color-text-header;
  }
}


