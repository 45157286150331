@import "variable";
//Bg-Layout
.bg__layout {
  background: $bg-layout;
}

//Success
.bg__color__success--dark {
  background: $bg-success-dark;
}
.bg__color__success--default {
  background: $bg-success-default;
}
.bg__color__success--light {
  background: $bg-success-light;
}
.bg__color__success--bg {
  background: $bg-success-bg;
}

//Warning
.bg__color__warning--dark {
  background: $bg-warning-dark;
}
.bg__color__warning--default {
  background: $bg-warning-default;
}
.bg__color__warning--light {
  background: $bg-warning-light;
}
.bg__color__warning--bg {
  background: $bg-warning-bg;
}

//Alert
.bg__color__alert--dark {
  background: $bg-alert-dark;
}
.bg__color__alert--default {
  background: $bg-alert-default;
}
.bg__color__alert--light {
  background: $bg-alert-light;
}
.bg__color__alert--bg {
  background: $bg-alert-bg;
}

//Error
.bg__color__error--dark {
  background: $bg-error-dark;
}
.bg__color__error--default {
  background: $bg-error-default;
}
.bg__color__error--light {
  background: $bg-error-light;
}
.bg__color__error--bg {
  background: $bg-error-bg;
}

//Primary
.bg__color__primary--dark {
  background: $bg-primary-dark;
}
.bg__color__primary--default {
  background: $bg-primary-default;
}
.bg__color__primary--light {
  background: $bg-primary-light;
}
.bg__color__primary--bg {
  background: $bg-primary-bg;
}

//Dayblue
.bg__color__dayblue--dark {
  background: $bg-dayblue-dark;
}
.bg__color__dayblue--default {
  background: $bg-dayblue-default;
}
.bg__color__dayblue--light {
  background: $bg-dayblue-light;
}
.bg__color__dayblue--bg {
  background: $bg-dayblue-bg;
}

//Secondary
.bg__color__secondary--dark {
  background: $bg-secondary-dark;
}
.bg__color__secondary--default {
  background: $bg-secondary-default;
}
.bg__color__secondary--light {
  background: $bg-secondary-light;
}
.bg__color__secondary--bg {
  background: $bg-secondary-bg;
}

//Gray
.bg__color__gray--dark {
  background: $bg-gray-dark;
}
.bg__color__gray--body {
  background: $bg-gray-body;
}
.bg__color__gray--label {
  background: $bg-gray-label;
}
.bg__color__gray--placeholder {
  background: $bg-gray-placeholder;
}
.bg__color__gray--line {
  background: $bg-gray-line;
}
.bg__color__gray--input {
  background: $bg-gray-input;
}
.bg__color__gray--input-light {
  background: $bg-gray-input-light;
}
.bg__color__gray--bg {
  background: $bg-gray-bg;
}
.bg__color__gray--off-white {
  background: $bg-gray-off-white;
}

//Content
.bg__content--default {
  background: $bg-content-default;
}
