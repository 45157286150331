// Fonts
//
// Font-weight, line-height, font-size
$font-family-sans-serif: 'avertaR';
$font-family-sans-serif-semibold: 'avertaSB';
$font-family-sans-serif-bold:'avertaB';


$font-weight-light:         300 !default;
$font-weight-normal:        400 !default;
$font-weight-semibold:      500 !default;
$font-weight-bold:          bold !default;
$font-weight-base:          $font-weight-normal !default;

$line-height-base:          1.4 !default;

$font-size-base:            0.875rem !default;
$font-size-xl:              1.1rem !default;
$font-size-lg:              1.1rem !default;
$font-size-sm:              .875rem !default;
$font-size-xs:              .75rem !default;

$display1-size:             6rem !default;
$display2-size:             5.5rem !default;
$display3-size:             4.5rem !default;
$display4-size:             3.5rem !default;

$display1-weight:           300 !default;
$display2-weight:           300 !default;
$display3-weight:           300 !default;
$display4-weight:           300 !default;

$display-line-height:       1.1 !default;

$lead-font-size:            1.25rem !default;
$lead-font-weight:          300 !default;

$small-font-size:           80% !default;
