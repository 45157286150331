@import '~ngx-toastr/toastr';
@import "variable";

.toast-container {
  .ngx-toastr {
    min-height: 48px;
    padding: 14px 16px;
    border-radius: 4px;
    color: rgba(255, 255, 255, .7);
    background-color: #323232;

    &:hover {
    }
  }

  .toast-success,
  .toast-info,
  .toast-warning,
  .toast-error {
    padding: 15px 15px 15px 15px;
    border-radius: 0px;
    border-left-width: 8px;
    border-left-style: solid;
  }

  .toast-success {
    background-color: white;
    border-color: #4caf50;
    color: #4caf50;
  }

  .toast-info {
    background-color: white;
    border-color: #2196f3;
    color: #2196f3;
  }

  .toast-warning {
    background-color: white;
    border-color: #ff9800;
    color: #ff9800;
  }

  .toast-error {
    background-color: white;
    border-color: #f44336;
    color: #f44336;
  }

  .toast-close-button {
    font-size: inherit;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .25);

    &:hover {
      color: inherit;
      opacity: .6;
    }
  }
}

.toast-top-center {
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

