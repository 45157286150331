@import "variable";
@import "theme/font";
.card__item {
  display: inline-block;

  > a {
    display: inline-block;
    padding: 20px 10px;
    background: #fff;
    text-align: center;
    min-width: 198px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #e8eaf6;
  }

  .card__item--title {
    color: $color-breadcrumb-title-active;
    margin-top: 12px;
    font-family: 'avertaB';
    font-size: $font-size-base;
  }
}
@media screen and (max-width: 1756px) {
  .card__item {
    display: inline-block;

    > a {
      padding: 15px 0px;
      width: 169px;
      min-width: 8.6rem;
    }

  }
}
@media screen and (max-width: 1656px) {
  .card__item {
    display: inline-block;

    > a {
      padding: 15px 0px;
      width: 159px;
      min-width: 8.6rem;
    }

  }
}
@media screen and (max-width: 1556px) {
  .card__item {
    display: inline-block;

    > a {
      padding: 15px 0px;
      width: 149px;
      min-width: 8.6rem;
    }

  }
}
@media screen and (max-width: 1440px) {
  .card__item {
    display: inline-block;

    > a {
      padding: 15px 0px;
      width: 129px;
      min-width: 129px;
    }

    .card__item--title {
      color: $color-breadcrumb-title-active;
      margin-top: 12px;
      font-family: 'avertaB';
      font-size: $font-size-base;
    }
  }
}
