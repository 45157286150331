$bg-table-header: #E8EAF6;
$color-text-header: #4E4B66;
$bg-border-table: #F4F6FA;


.ant-table-content {
  table {
    border-left: 1px solid $bg-border-table;
    border-right: 1px solid $bg-border-table;
  }

  .ant-table-thead > tr > th {
    background: $bg-table-header;
    color: $color-text-header;
    font-family: 'avertaB';
//     text-transform: uppercase;
  }

}

.ant-pagination {
  margin-top: 16px;
  float: right;
}
